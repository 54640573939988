export function getAdminDefaultPath() {
  return '/admin/adjustments';
}
export function getManagerDefaultPath() {
  return '/manager/adjustments';
}

export function getUserDefaultPath() {
  return '/user/charges';
}
