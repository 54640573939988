import { getMe } from 'commons/auth/api';

import { useSuspensedQuery } from '@quotalab/react-query';

export const UserQueryKey = {
  default: () => ['user'],
  me: () => [...UserQueryKey.default(), 'me'],
};

export function useMe() {
  return useSuspensedQuery(UserQueryKey.me(), () => getMe(), { retry: false });
}
