import { clearAuthorization } from 'commons/auth/utils';
import { clearPersistQueryCache } from 'shared/utils/persistQuery';

import { useLoginStatus } from './useLoginStatus';

import { iif, stringifyQueryParams } from '@quotalab/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

function useAuthorizedRoute() {
  const router = useRouter();
  const { data: isLoggedIn } = useLoginStatus();
  const queryClient = useQueryClient();

  if (router.isReady && isLoggedIn === false) {
    clearAuthorization();
    clearPersistQueryCache(queryClient);
    router.replace(
      `/login${stringifyQueryParams({
        redirectUrl: iif(
          window.location.pathname !== '/' && window.location.pathname !== '/login',
          window.location.pathname,
        ),
      })}`,
    );
    throw new Promise(() => {});
  }
}

export default useAuthorizedRoute;
