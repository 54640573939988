import { identify, omit } from '@quotalab/utils';
import axios, {
  type AxiosError,
  type AxiosResponse,
  isAxiosError,
  type AxiosRequestConfig,
  type RawAxiosResponseHeaders,
  type AxiosResponseHeaders,
} from 'axios';
import { isQualifiedPath } from 'shared/utils/url';

export type HTTPMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD' | 'OPTIONS';
interface RawResponse<T = unknown> {
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  body: T;
  status: number;
}

type RequestConfig = Omit<
  AxiosRequestConfig,
  | 'url'
  | 'transformRequest'
  | 'transformResponse'
  | 'adapter'
  | 'auth'
  | 'xsrfCookieName'
  | 'xsrfHeaderName'
  | 'socketPath'
  | 'proxy'
  | 'decompress'
  | 'transitional'
  | 'cancelToken'
  | 'env'
  | 'insecureHTTPParser'
  | 'formSerializer'
  | 'method'
> & {
  method?: HTTPMethods;
  interceptors?: {
    request?: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>;
    response?: <T>(response: AxiosResponse) => Promise<AxiosResponse<T>>;
    onError?: (error: AxiosError) => Promise<unknown>;
  };
};
const convertToAxiosRequestConfig = (config: RequestConfig) => {
  return omit(config, ['interceptors']) as AxiosRequestConfig;
};

const asyncIdentify = <T>(v: T) => Promise.resolve(identify(v));

const rawRequest =
  (baseURL: string, baseConfig: RequestConfig) =>
  async <Response>(path: string, requestConfig?: RequestConfig) => {
    const requestInterceptor =
      requestConfig?.interceptors?.request ?? baseConfig.interceptors?.request ?? asyncIdentify;
    const config = await requestInterceptor(
      convertToAxiosRequestConfig({
        ...baseConfig,
        ...requestConfig,
      }),
    );

    try {
      const response = await axios.request<Response>({
        url: isQualifiedPath(path) ? path : `${baseURL}${path[0] === '/' ? path : `/${path}`}`,
        ...config,
      });

      const responseInterceptor =
        requestConfig?.interceptors?.response ?? baseConfig.interceptors?.response;
      const result = (await responseInterceptor?.(response)) ?? response;

      return {
        headers: result.headers,
        body: result.data,
        status: result.status,
      } as RawResponse<Response>;
    } catch (e) {
      const errorHandler =
        requestConfig?.interceptors?.onError ?? baseConfig.interceptors?.onError ?? asyncIdentify;
      if (isAxiosError(e)) {
        throw await errorHandler(e);
      }

      throw e;
    }
  };

const request =
  (baseURL: string, baseConfig: RequestConfig) =>
  async <Response>(path: string, requestConfig?: RequestConfig) => {
    return (await rawRequest(baseURL, baseConfig)<Response>(path, requestConfig)).body;
  };

const get =
  (baseURL: string, baseConfig: RequestConfig) =>
  <Response>(path: string, requestConfig?: Omit<RequestConfig, 'method'>) => {
    return request(baseURL, baseConfig)<Response>(path, {
      ...requestConfig,
      method: 'GET',
    });
  };

const post =
  (baseURL: string, baseConfig: RequestConfig) =>
  <Response>(path: string, body?: unknown, requestConfig?: Omit<RequestConfig, 'method'>) => {
    return request(baseURL, baseConfig)<Response>(path, {
      ...requestConfig,
      data: body,
      method: 'POST',
    });
  };

const put =
  (baseURL: string, baseConfig: RequestConfig) =>
  <Response>(path: string, body?: unknown, requestConfig?: Omit<RequestConfig, 'method'>) => {
    return request(baseURL, baseConfig)<Response>(path, {
      ...requestConfig,
      data: body,
      method: 'PUT',
    });
  };

const patch =
  (baseURL: string, baseConfig: RequestConfig) =>
  <Response>(path: string, body?: unknown, requestConfig?: Omit<RequestConfig, 'method'>) => {
    return request(baseURL, baseConfig)<Response>(path, {
      ...requestConfig,
      data: body,
      method: 'PATCH',
    });
  };

const _delete =
  (baseURL: string, baseConfig: RequestConfig) =>
  <Response>(path: string, requestConfig?: Omit<RequestConfig, 'method'>) => {
    return request(baseURL, baseConfig)<Response>(path, {
      ...requestConfig,
      method: 'DELETE',
    });
  };

export const createHTTPClient = (baseURL: string, baseConfig: RequestConfig) => {
  return {
    get: get(baseURL, baseConfig),
    rawRequest: rawRequest(baseURL, baseConfig),
    post: post(baseURL, baseConfig),
    put: put(baseURL, baseConfig),
    patch: patch(baseURL, baseConfig),
    delete: _delete(baseURL, baseConfig),
    request: request(baseURL, baseConfig),
  };
};
