import {
  type LoginRequest,
  type RefreshAuthRequest,
  type TokenResponse,
  type UserInfo,
} from 'commons/auth/model';
import { clearAuthorization } from 'commons/auth/utils';
import apiClient, { authClient } from 'shared/api/apiClient';

export const login = (request: LoginRequest) => {
  return authClient.post('/api/auth/signin', request);
};

export const logout = () => {
  clearAuthorization();
};

export const refreshAuth = (request: RefreshAuthRequest) => {
  return authClient.post<TokenResponse>('/api/auth/refresh', request, {
    headers: {
      Authorization: null,
    },
  });
};

export const getMe = () => {
  return apiClient.get<UserInfo>('/api/auth/me');
};
