import { useEffect } from 'react';

import useAuthorizedRoute from 'commons/auth/hooks/useAuthorizedRoute';
import { useMe } from 'commons/auth/hooks/useMe';
import {
  getAdminDefaultPath,
  getManagerDefaultPath,
  getUserDefaultPath,
} from 'commons/auth/utils/route';

import { useRouter } from 'next/router';
import { match } from 'ts-pattern';

const Index = () => {
  useAuthorizedRoute();
  const router = useRouter();
  const { data: me } = useMe();

  useEffect(() => {
    router.replace(
      match(me)
        .with({ user: { role: 'MASTER' } }, () => getAdminDefaultPath())
        .with({ user: { role: 'MANAGER' } }, () => getManagerDefaultPath())
        .with({ user: { role: 'USER' } }, () => getUserDefaultPath())
        .exhaustive(),
    );
  }, [me, router]);

  return <div />;
};

export default Index;
